import { boot } from 'quasar/wrappers';
import { LocalStorage } from 'quasar';
import axios, { AxiosInstance } from 'axios';
import { useUserStore } from 'stores/user';

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $axios: AxiosInstance;
    $api: AxiosInstance;
  }
}

// Be careful when using SSR for cross-request state pollution
// due to creating a Singleton instance here;
// If any client changes this (global) instance, it might be a
// good idea to move this instance creation inside of the
// "export default () => {}" function below (which runs individually
// for each client)
const api = axios.create({ baseURL: process.env.API_URL });

api.interceptors.request.use(async (req) => {
  const token = LocalStorage.getItem('cogniscript_id_token');
  if (token) {
    req.headers.Authorization = `Bearer ${token}`;
    //console.log('Adding ID token: ' + token);
  }
  return req;
});

export default boot(({ app, router }) => {
  api.interceptors.response.use(
    async (response) => {
      return response;
    },
    async (error) => {
      if (401 === error.response?.status) {
        router.push({ path: '/login' });
      } else if (403 === error.response?.status) {
        const user = useUserStore();
        await user.reload_user();
        router.push({ path: '/profile' });
      }
      return Promise.reject(error);
    }
  );

  // for use inside Vue files (Options API) through this.$axios and this.$api

  app.config.globalProperties.$axios = axios;
  // ^ ^ ^ this will allow you to use this.$axios (for Vue Options API form)
  //       so you won't necessarily have to import axios in each vue file

  app.config.globalProperties.$api = api;
  // ^ ^ ^ this will allow you to use this.$api (for Vue Options API form)
  //       so you can easily perform requests against your app's API
});

export { api };
