import { defineStore } from 'pinia';
import { api } from 'src/boot/axios';
import { PostCategory, Topic } from 'components/models';
import { useUserStore } from './user';

type ThisStoreState = {
  topics: Topic[];
  content_plan_id: number;
  categories: PostCategory[];
  error: boolean;
  loading_topics: boolean;
  loading_categories: boolean;
  loaded_categories: boolean;
};

function fetch_topics(state: ThisStoreState, content_plan_id: number) {
  if (!content_plan_id) {
    return;
  }
  state.loading_topics = true;
  state.error = false;
  state.content_plan_id = content_plan_id;
  api
    .get('/content_plan_topics/' + content_plan_id)
    .then((res) => {
      state.topics = res.data;
      state.error = false;
    })
    .catch(() => {
      state.error = true;
    })
    .finally(() => (state.loading_topics = false));
}

function fetch_categories(state: ThisStoreState) {
  state.loading_categories = true;
  state.error = false;
  api
    .get('/post_categories/')
    .then((res) => {
      state.categories = res.data;
      state.loaded_categories = true;
      state.error = false;
    })
    .catch(() => {
      state.error = true;
    })
    .finally(() => (state.loading_categories = false));
}

export const useTopicsStore = defineStore('topics', {
  state: () =>
    ({
      topics: [],
      content_plan_id: 0,
      categories: [],
      error: false,
      loading_topics: false,
      loading_categories: false,
      loaded_categories: false,
    } as ThisStoreState),

  getters: {
    loading(state) {
      return state.loading_topics || state.loading_categories;
    },
    get_topics(state) {
      return (content_plan_id: number) => {
        if (state.content_plan_id != content_plan_id) {
          state.topics = [];
          fetch_topics(state, content_plan_id);
        }
        return state.topics;
      };
    },
    get_categories(state) {
      if (!state.loaded_categories) {
        fetch_categories(state);
      }
      return state.categories;
    },
    get_user_personal_texts(/*state*/): Topic[] {
      const userStore = useUserStore();
      if (userStore.user.active_profile.personal_texts_id) {
        return this.get_topics(userStore.user.active_profile.personal_texts_id);
      } else {
        return [];
      }
    },
    get_user_image_requests(/*state*/): Topic[] {
      const userStore = useUserStore();
      if (userStore.user.active_profile.image_requests_id) {
        return this.get_topics(userStore.user.active_profile.image_requests_id);
      } else {
        return [];
      }
    },
    get_topic(state) {
      return (topic_id: number, content_plan_id = 0) => {
        let found_topics: Topic[] = [];

        if (state.topics.length > 0) {
          found_topics = state.topics.filter(function (topic) {
            return topic.id == topic_id;
          });
        }
        if (found_topics.length == 0) {
          /**
           * We are here most likely because the user has reloaded the Post
           * page. The store is now empty, and for this reason the topic is not
           * found.
           *
           * So, we are going to reload the topics now for the content_plan_id
           * that is kept in the User object.
           *
           * But the process of fetching the topics from the API is asyncronous,
           * so we won't get immediate result right now. For this reason, we are
           * going to return an 'Empty Topic' here.
           */
          const userStore = useUserStore();
          this.get_topics(content_plan_id || userStore.content_plan_id);

          const emptyTopic: Topic = {
            id: 0,
            sorting_number: 0,
            topic: '',
            description: '',
            instructions: '',
            content_type: 'user_text',
            content_plan_id: 0,
            post_category_id: 0,
            can_change_category: false,
          };
          return emptyTopic;
        }
        return found_topics[0];
      };
    },
  },
  actions: {
    reload_topics() {
      fetch_topics(this, this.content_plan_id);
    },
    save_topic_edits(updated_topic: Topic) {
      const t_index = this.topics.findIndex(
        (topic) => topic.id == updated_topic.id
      );
      if (t_index >= 0) {
        this.topics[t_index] = updated_topic;
      }

      api
        .patch('/post_details/' + updated_topic.id, updated_topic)
        .catch(() => {
          this.error = true;
        });
    },
    create_new_topic(new_topic: Topic) {
      this.loading_topics = true;
      api
        .post('/post_details/', new_topic)
        .then((res) => {
          this.process_addition(this, '/post', res.data);
          this.reload_user_if_personal_texts_id_not_set();
        })
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.loading_topics = false;
        });
    },
    delete_topic_on_frontend(post_id: number) {
      const topic_index = this.topics.findIndex((topic) => topic.id == post_id);
      if (topic_index >= 0) {
        this.topics.splice(topic_index, 1);
      }
    },
    process_addition(
      state: ThisStoreState,
      path_base: string,
      new_record: Topic
    ) {
      if (new_record?.content_plan_id && new_record?.id) {
        this.router.push(
          path_base + '/' + new_record.content_plan_id + '/' + new_record.id
        );
      }
      fetch_topics(state, new_record.content_plan_id); // it will clear this.loading when complete
    },
    reload_user_if_personal_texts_id_not_set() {
      /**
       * If this was the user's first personal text, we've probably just
       * updated (set) the personal_texts_id for him, so we want to re-fetch
       * the user object now.
       */
      const userStore = useUserStore();
      if (!userStore.user.active_profile.personal_texts_id) {
        userStore.reload_user();
      }
    },
    reload_user_if_image_requests_id_not_set() {
      /**
       * If this was the user's first image request, we've probably just
       * updated (set) the image_requests_id for him, so we want to re-fetch
       * the user object now.
       */
      const userStore = useUserStore();
      if (!userStore.user.active_profile.image_requests_id) {
        userStore.reload_user();
      }
    },
    async set_post_category(topic: Topic, category_id: number) {
      this.loading_topics = true;
      await api
        .post('/post_categories/' + topic.id + '/' + category_id)
        .then(() => {
          topic.post_category_id = category_id;
        })
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.loading_topics = false;
        });
    },
    save_user_personal_text(new_text: string) {
      this.loading_topics = true;
      api
        .post('/texts/', new_text)
        .then((res) => {
          this.process_addition(this, '/post', res.data);
          this.reload_user_if_personal_texts_id_not_set();
        })
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.loading_topics = false;
        });
    },
    save_user_image_request(new_request: string) {
      this.loading_topics = true;
      api
        .put('/images/', new_request)
        .then((res) => {
          this.process_addition(this, '/images', res.data);
          this.reload_user_if_image_requests_id_not_set();
        })
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.loading_topics = false;
        });
    },
  },
});
