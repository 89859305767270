import { defineStore } from 'pinia';
import { api } from 'src/boot/axios';
import { ChatStatus, ChatMessage } from 'components/models';
import { useUserStore } from './user';

const COGNISCRIPT_STATUS_TIMEOUT = 60 * 1000; // milliseconds
const COGNISCRIPT_STATUS_REQUESTED = 'requested'; // we are waiting for a response
const COGNISCRIPT_STATUS_AVAILABLE = 'available'; // response available
const COGNISCRIPT_STATUS_CREATED = 'created'; // new chat has just been created, no request sent

const COGNISCRIPT_INITIAL_STATUS: ChatStatus = {
  chat_status: COGNISCRIPT_STATUS_CREATED,
  status_changed: new Date('2021-07-21'),
};

type ThisStoreState = {
  status: ChatStatus;
  chat_messages: ChatMessage[];
  post_id: number;
  error: boolean;
  loading_status: boolean;
  loading_messages: boolean;
  reload_messages: boolean;
};

function fetch_chat_messages(state: ThisStoreState, post_id: number) {
  state.loading_messages = true;
  state.error = false;
  api
    .get('/chat_messages/' + post_id)
    .then((res) => {
      state.chat_messages = res.data;
      state.post_id = post_id;
      state.reload_messages = false;
    })
    .catch(() => {
      state.error = true;
    })
    .finally(() => (state.loading_messages = false));
}

function instore_status_expired(state: ThisStoreState) {
  const latest_not_expired = new Date(Date.now() - COGNISCRIPT_STATUS_TIMEOUT);

  return state.status.status_changed < latest_not_expired;
}

function fetch_chat_status(state: ThisStoreState, post_id: number) {
  state.loading_status = true;
  state.error = false;
  api
    .get('/chats/' + post_id)
    .then((res) => {
      /**
       * We begin with a consistncy check. If for whatever reason, the stored
       * post_id is different, all the stored data needs to be discarded.
       */
      if (state.post_id != post_id) {
        state.status = COGNISCRIPT_INITIAL_STATUS;
        state.chat_messages = [];
        state.reload_messages = true;
      }
      state.post_id = post_id;

      let status_in_db: ChatStatus;

      if (res.status == 204) {
        /**
         * HTTP_204_NO_CONTENT -- this means, there is no existing chat in the
         * DB for this post yet. This is equivalent for a chat to be just
         * created, so make up this status locally (instead of polluting the DB
         * by actually creating this object, which may turn out to be not
         * actually needed.)
         */
        status_in_db = {
          chat_status: COGNISCRIPT_STATUS_CREATED,
          status_changed: new Date(),
        };
      } else {
        status_in_db = res.data;
        status_in_db.status_changed = new Date(status_in_db.status_changed);
      }

      // console.log('local_status = ', state.status);
      // console.log('status_from_db = ', status_in_db);

      /**
       * Now our goal is to determine whether we should update the local
       * in-memory status with the one just read from the DB. We
       * want to do the update in the following cases:
       * 1) Local status not set
       * 1) There is a newer status in DB
       * 2) A predefined timeout has elapsed since the local status was set
       *    (that would mean something went wrong and we basically want to
       *    ignore the previous local status setting)
       */

      // if (status_in_db.status_changed > state.status.status_changed) {
      //   console.log('Status in DB is newer');
      // }

      // if (instore_status_expired(state)) {
      //   console.log('Instore status expired');
      // }

      if (
        status_in_db.status_changed > state.status.status_changed ||
        instore_status_expired(state)
      ) {
        if (
          state.status.chat_status == COGNISCRIPT_STATUS_REQUESTED &&
          status_in_db.chat_status == COGNISCRIPT_STATUS_AVAILABLE
        ) {
          //A new text just became available.
          fetch_chat_messages(state, post_id);
        }
        state.status = status_in_db;
      }
    })
    .catch(() => {
      state.error = true;
    })
    .finally(() => (state.loading_status = false));
}

export const useChatMessagesStore = defineStore('chatMessages', {
  state: () =>
    ({
      status: COGNISCRIPT_INITIAL_STATUS,
      chat_messages: [],
      post_id: 0,
      error: false,
      loading_status: false,
      loading_messages: false,
      reload_messages: false,
    } as ThisStoreState),

  getters: {
    loading(): boolean {
      return this.loading_messages;
    },
    is_text_being_generated(): boolean {
      return this.status.chat_status == COGNISCRIPT_STATUS_REQUESTED;
    },
    get_chat_messages(state) {
      return (post_id: number) => {
        /**
         * Empty chat messages is a valid situation and we don't want to keep
         * reloading them in that case. So we verify for post_id only.
         */
        if (state.post_id != post_id) {
          state.chat_messages = [];
          state.reload_messages = true;
        }
        if (state.reload_messages) {
          fetch_chat_messages(state, post_id);
        }
        return state.chat_messages;
      };
    },
    get_chat_message(state) {
      return (message_id: number) => {
        const found_messages = state.chat_messages.filter(function (msg) {
          return msg.id == message_id;
        });
        if (found_messages.length > 0) {
          return found_messages[0];
        }
      };
    },
    get_status(state) {
      return (post_id: number) => {
        if (
          state.post_id != post_id ||
          state.status == COGNISCRIPT_INITIAL_STATUS
        ) {
          state.status = COGNISCRIPT_INITIAL_STATUS;
          fetch_chat_status(state, post_id);
        }
        return state.status;
      };
    },
  },
  actions: {
    mark_read(message_id: number) {
      const m_index = this.chat_messages.findIndex(
        (message) => message.id == message_id
      );
      if (m_index >= 0) {
        this.chat_messages[m_index].seen = true;
      }
      api.patch('/chat_messages/' + message_id).catch(() => {
        this.error = true;
      });
    },
    delete_chat_message(message_id: number) {
      /**
       * First, delete on the backend and then, if successful, update the
       * front-end chat_messages array.
       * We used to do it the other way around but it presents a problem when
       * we need to watch 'chat_messages' and re-fetch the topics (user-provided
       * texts) when the last chat_message is deleted. The re-fetch in this case
       * must happen after the message has been deleted on the backend.
       */
      api
        .delete('/chat_messages/' + message_id)
        .then(() => {
          const m_index = this.chat_messages.findIndex(
            (message) => message.id == message_id
          );
          if (m_index >= 0) {
            this.chat_messages.splice(m_index, 1);
          }
        })
        .catch(() => {
          this.error = true;
        });
    },
    save_updated_chat_message(message_id: number) {
      /**
       * We are assuming the message has already been updated in the store, and
       * now our goal is to propagate the update to the backend.
       */
      const m_index = this.chat_messages.findIndex(
        (message) => message.id == message_id
      );
      if (m_index >= 0) {
        api
          .put(
            '/chat_messages/' + message_id,
            this.chat_messages[m_index].content
          )
          .then(() => {
            this.chat_messages[m_index].edited_at = Date.now() / 1000;
          })
          .catch(() => {
            this.error = true;
          });
      }
    },
    save_feedback_on_message(message_id: number, positive: boolean) {
      const m_index = this.chat_messages.findIndex(
        (message) => message.id == message_id
      );
      if (m_index >= 0) {
        this.chat_messages[m_index].user_feedback = positive ? 1 : -1;
        api
          .post(
            '/chat_messages/' + message_id,
            this.chat_messages[m_index].user_feedback
          )
          .catch(() => {
            this.error = true;
          });
      }
    },
    async delete_chat() {
      api
        .delete('/chats/' + this.post_id)
        .then((res) => {
          this.chat_messages = [];
          this.status = res.data;
        })
        .catch(() => {
          this.error = true;
        });
    },
    request_text(prompt_id: number) {
      api
        .post('/chats/' + this.post_id + '/' + prompt_id)
        .then(() => {
          const user = useUserStore();
          if (
            user.user.subscription_plan?.has_limit &&
            user.user.credits_remaining > 0
          ) {
            user.user.credits_remaining--;
          }
        })
        .catch(() => {
          this.error = true;
        });
      this.set_requested_status();
    },
    set_requested_status() {
      this.status = {
        chat_status: COGNISCRIPT_STATUS_REQUESTED,
        status_changed: new Date(),
      };
    },
    force_status_update() {
      fetch_chat_status(this, this.post_id);
    },
  },
});
